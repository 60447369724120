import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { device } from '../../theme';

export const CaseStudyWrapper = styled(BackgroundImage)`
  padding-top: 100px;
  padding-bottom: 100px;
  background-position: bottom left;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.light};
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;
