import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Benefits from '../Benefits';

const BenefitArea = () => {
  const benefitData = useStaticQuery(graphql`
    query ProcessingBenefitQuery {
      indexJson(id: { eq: "benefit-section" }) {
        title
        subtitle
        primaryAction {
          label
          link
        }
        benefits
      }
    }
  `);

  const { title, subtitle, benefits, primaryAction } = benefitData.indexJson;
  return (
    <>
      <Benefits itemsFilter={benefits} title={title} subtitle={subtitle} primaryAction={primaryAction} />
    </>
  );
};

export default BenefitArea;
