import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import PuffLoader from 'react-spinners/PuffLoader';
import { Alert } from 'react-bootstrap';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import SectionTitle from '../../components/ui/section-title';
import Text from '../../components/ui/Text';
import Anchor from '../../components/ui/Anchor';
import CaseStudyBox from '../../components/box-large-image/layout-two';
import SwiperSlider from '../../components/ui/swiper';
import { CaseStudyWrapper } from './courses.style';
import useSiteLocation from '../../hooks/useSiteLocation';
import productsService from '../../services/productsService';
import { LoadingContainer } from '../site-selection/styles';
import { theme } from '../../theme';
import { ItemProduct } from '../../components/ProductWP/styles';

const EcommerceCourses = ({
  sectionTitleStyle,
  bottomTextStyle,
  bottomTextLinkStyle,
  caseStudyStyles,
  slider,
  sliderStyle,
}) => {
  const coursesData = useStaticQuery(graphql`
    query EcommerceCoursesQuery {
      indexJson(id: { eq: "courses-section" }) {
        id
        title
        subtitle
        bottom_text
        bottom_link_text_online
        bottom_link_online
        bgImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const generalInfo = coursesData.indexJson;
  const imageData = generalInfo.bgImage.childImageSharp.fluid;

  useEffect(() => {
    productsService.products().then(response => {
      setCourses([...response.data]);
      setLoading(false);
    });
  }, []);

  return (
    <CaseStudyWrapper fluid={imageData}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} title={generalInfo.title} subtitle={generalInfo.subtitle} />
          </Col>
        </Row>
        <Row>
          {loading && (
            <Col lg={12}>
              <LoadingContainer>
                <PuffLoader color={theme.colors.primary} size={60} />
              </LoadingContainer>
            </Col>
          )}
          {!loading && courses.length === 0 && (
            <Col lg={12}>
              <Alert align="center" variant="warning">
                <>
                  <Text mb="0" fontWeight="bold">
                    Não existem cursos para a categoria selecionada
                  </Text>
                </>
              </Alert>
            </Col>
          )}
          {!loading && courses.length > 0 && (
            <Col lg={12}>
              <SwiperSlider {...sliderStyle} settings={slider}>
                {courses.map(course => (
                  <ItemProduct className="item" key={course.id}>
                    <CaseStudyBox
                      {...caseStudyStyles}
                      imageSrc={course.images[0]?.src}
                      title={course.name}
                      category={course.categories[0]?.name}
                      desc={course.price_html}
                      path={course.permalink}
                      btnText="Saiba mais"
                    />
                  </ItemProduct>
                ))}
              </SwiperSlider>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            <Text {...bottomTextStyle}>
              {generalInfo.bottom_text}
              <Anchor {...bottomTextLinkStyle} path={generalInfo.bottom_link}>
                {generalInfo.bottom_link_text}
              </Anchor>
            </Text>
          </Col>
        </Row>
      </Container>
    </CaseStudyWrapper>
  );
};

EcommerceCourses.propTypes = {
  sectionTitleStyle: PropTypes.object,
  bottomTextStyle: PropTypes.object,
  bottomTextLinkStyle: PropTypes.object,
  caseStudyStyles: PropTypes.object,
  slider: PropTypes.object,
  sliderStyle: PropTypes.object,
};

EcommerceCourses.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  bottomTextStyle: {
    fontSize: '18px',
    fontweight: 500,
    lineHeight: 1.4,
    color: '#333333',
    mt: '40px',
    align: 'center',
  },
  bottomTextLinkStyle: {
    fontWeight: 500,
    layout: 'underline',
    hover: {
      layout: 2,
    },
  },
  slider: {
    autoplay: true,
    slidesPerView: 3,
    speed: 1000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  sliderStyle: {
    pagination: {
      bottom: 0,
    },
  },
  caseStudyStyles: {
    boxStyle: {
      mt: '20px',
      mb: '35px',
      ml: '15px',
      mr: '15px',
    },
    contentBoxStyle: {
      pt: '25px',
      pl: '26px',
      pr: '26px',
      textalign: 'left',
    },
    headingStyle: {
      as: 'h6',
      fontweight: 600,
      mb: '2px',
    },
    descStyle: {
      mb: 0,
      mt: '13px',
    },
  },
};

export default EcommerceCourses;
