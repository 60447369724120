import React from 'react';
import SEO from '../../components/seo';
import Career from '../../containers/career';
import CarouselHero from '../../containers/CarouselHero/CarouselHero';
import ContactArea from '../../containers/contact-area';
import HeroArea from '../../containers/hero';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import Methodology from '../../containers/methodology';
import TestimonialSection from '../../containers/testimonial-area';
import BenefitArea from '../../containers/benefit-area';
import EcommerceCourses from '../../containers/EcommerceCourses';

const OnlinePage = () => (
  <>
    <SEO title="Cursos Preparatórios para Concursos Públicos e Militares - Garra - Online" />
    <Header />
    <CarouselHero />
    <HeroArea />
    <Career />
    <EcommerceCourses />
    <TestimonialSection />
    <BenefitArea />
    <Methodology />
    <ContactArea />
    <Footer />
  </>
);

export default OnlinePage;
